import React from 'react'
import { graphql } from 'gatsby'
import Image from '../components/Image'
import Layout from '../components/Layout'

export default function fourOhFour({ data }) {
  const seo = {
    title: 'Page not found',
    slug: '404',
    //image: data.image 
  }

  return (
    <Layout colorScheme="blanc" layout="basic" seo={seo}>
      <article>
        <h1>Ugh, page not found</h1>
        <Image data={data.image} />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query imageQuery {
    image: file(relativePath: { eq: "placeholders/fresheyes_bw.png" }) {
      ...superheroImage
    }
  }
`
